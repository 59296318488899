.Footer {
    width: 100%;
    height: 15vh;
    padding: 1rem 0.5rem;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}
.Footer-h5 {
    color: #fff;
    font-size: 12px;
}
.Footer-red {
    color: rgb(184, 8, 8);
}

@include breakpoint('medium-simple') {
    .Footer-h5 {
        font-size: 18px;
    }   
}