.Header{
    width: 100%;
    height: 100vh;
    background-image:url('../../assets/portada.jpg');
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    //filter: grayscale(80%);
}