.About {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 5rem 0.5rem;
}

.About-text {
    width: 100%;
}
.About-titulo {
    width: 100%;
    text-align: center;
    font-size: 35px;
    font-weight:900;
    margin-bottom: 2rem;
    color: rgb(184, 8, 8);
}
.About-content {
    width: 100%;
}    
.About-name {
    width: 100%;
    font-size: 25px;
    text-align: center;
    margin-bottom: 1rem;
    color: $Color-black;
}
.About-red {
    color: rgb(184, 8, 8);
    font-weight: 600;
}
.About-p {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
    color: $Color-black;
}
.About-personalInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    color: $Color-black;
}
.About-btn {
    color: #fff;
    text-align: center;
    width: 180px;
    height: 50px;
    background-color: rgb(184, 8, 8);
    border-radius: 15px;
    padding: 1rem;
    font-weight: 600;
    cursor: pointer;
    margin-top: 1rem;
}

.About-lenguage {
    width: 100%;
}
.About-h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: rgb(184, 8, 8);
}
.About-tool {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
}
.About-icon {
    width: 100px;
    height: 100px;
    img {
        max-width: 100%;
    }
}
@include breakpoint('medium-simple') {
    .About-name {
        text-align: left;
        padding-left: 1rem;
    }
    .About-p {
        text-align: justify;
        padding: 0 1rem;
    }
    .About-h4 {
       padding-left: 1rem;
    }
    .About-icon {
        width: 65px;
        height: 65px;
    }
     .About-personalInfo {
        width: 100%;
        justify-content: center;
        align-items: flex-start;
        padding-left: 1rem;
    }

}
@include breakpoint('medium-tablet') {
    

    .About-icon {
        width: 70px;
        height: 70px;
    }

}

@include breakpoint('medium-high') {
    .About-content {
        display: flex;
    }    
    .About-description {
        width: 65%;
        padding: 3rem;
    }
    .About-personalInfo {
        width: 35%;
    }
    .About-icon {
        width: 90px;
        height: 90px;
    }
    .About-h4 {
     padding-left: 3rem;
    }

}

@include breakpoint('large') {
    .About-tool {
        padding: 0 8rem;
     
    }
}

