.Portafolio {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 5rem 0.5rem;
}
.Portafolio-titulo {
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 35px;
    font-weight:900;
    margin-bottom: 2rem;
}

.Portafolio-contenedorCards {
    display: flex;
    flex-wrap: wrap;
    padding: 2px;
}

.Portafolio-column {
    display: flex;
    flex-direction: column;
    flex:100%;
    padding: 0.5rem;
    gap: 1rem;
}

.Portafolio-card {
    cursor: pointer;
    position: relative;
    height: auto;
    background-image: url('../../');
    img{
        border-radius: 5px;
    }
}
.Portafolio-hover {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    :hover {
        background-color: aqua;
    }
}

@include breakpoint('medium-tablet') {
    
.Portafolio-column {
    flex: 50%;
}

}

    
@include breakpoint('medium-high') {
    .Portafolio-column {
        flex: 25%;
    }
    
}
