.Slider {
    width: 100%;
    height: 100%;
    color:rgb(58, 58, 59);
    background-color: rgb(255, 255, 255,0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    p {
        -webkit-text-stroke: 1px black;
    }
}

.Slider-welcome {
    font-size: 45px;
    margin-bottom: 3rem;
}

.Slider-signo {
    color: rgb(184, 8, 8);
}

.Slider-name {
    font-size: 30px;
    margin-bottom: 1rem;
}

.Slider-subTitulo {
    font-size: 15px;
    margin-bottom: 2rem;
    font-weight: 700;
    
}

.Slider-btn {
    border: 3px solid rgb(184, 8, 8);
    border-radius: 15px;
    padding: 1rem;
    font-weight: 600;
    cursor: pointer;
}

@include breakpoint('medium-tablet') {
    

    .Slider-welcome {
        font-size: 80px;
        margin-bottom: 5rem;
    }
    
    .Slider-signo {
        color: rgb(184, 8, 8);
    }
    
    .Slider-name {
        font-size: 65px;
        margin-bottom: 2rem;
    }
    
    .Slider-subTitulo {
        font-size: 25px;
        margin-bottom: 6rem;
        font-weight: 700;
        
    }
    
    .Slider-btn {
        border: 3px solid rgb(184, 8, 8);
        border-radius: 15px;
        padding: 1rem;
        font-weight: 600;
        cursor: pointer;
    }
}