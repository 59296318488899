.Contact {
    width: 100%;
    height: 100vh;
    padding: 5rem 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Contact-titulo {
    width: 100%;
    text-align: center;
    font-size: 35px;
    font-weight:900;
    margin-bottom: 2rem;
    color: rgb(184, 8, 8);
}
.Contact-container {
    width: 100%;
    
}
.Contact-text {
    color: $Color-black;
}
.Contact-Form {
    width: 100%;
}
form{
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Contact-inputs {
    width: 100%;    
}
.Contact-input {
    width: 100%;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.8);
    margin: 1rem 0;
    border-radius: 5px;
    padding: 1rem;
    color: #fff;
}
.Contact-textArea {
    width: 100%;
    height: 150px;
    background-color: rgba(0, 0, 0, 0.8);
    margin: 1rem 0;
    border-radius: 5px;
    padding: 1rem;
    color: #fff;
}
.Contact-btn {
    width: 100%;
    height: 40px;
    background-color: rgb(184, 8, 8);
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
}
.Contact-btn:hover {
    background-color: rgb(184, 8, 8,0.5);
}
.Contact-p {
    color: rgb(184, 8, 8);
}
@include breakpoint('medium-simple') {
    form{
        
    }
    .Contact-inputs {
        width: 70%;    
    }
    .Contact-input {
        width: 100%;
    }
    .Contact-textArea {
        width: 70%;
    }
    .Contact-btn {
        width: 70%;
    }
}

@include breakpoint('medium-tablet') {
    
}

@include breakpoint('medium-high') {
    .Contact-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2.5rem;
    }
    .Contact-text {
        width: 40%;
    }
    .Contact-Form {
        width: 60%;
    }
    .Contact-btn { 
        width: 200px;
    }
}